<template>
  <div id="img">
    <a :href = paymentURL @click.prevent="handleClick">
      <!-- <img :src = paymentPicURL alt="HealthyGacha Payment Picture"> -->
      <img src = "./assets/42B05A6CD64DB2BA3BA80935372A15B0DD0CCD30.png" alt="HealthyGacha Payment Picture">
    </a>
  </div>
</template>

<script>
import axios from "axios";
const liff = require('@line/liff');

let backURL = process.env.VUE_APP_backURL;
export default {
  data() {
    return {
      basePaymentPicURL: process.env.VUE_APP_paymentPicURL,
      paymentURL: '',
      paymentPicURL:  '',
      userId: '',
      displayName: '',
      pictureUrl: '',
      email: '',
      currentEnvId: '',
      envInfo: [],
    };
  },
  methods: {
    // Update user info from Liff to database
    liffDataGacha() {
      liff.init({
        liffId: process.env.VUE_APP_liffId
      }).then(() => {
        //Check user login status
        let isLoggedIn;
        isLoggedIn = liff.isLoggedIn();
        if (isLoggedIn) {
          // Need to open "profile", "openid", & "email" in Line console LIFF page, Scope.
          // Get user profile
          liff.getProfile().then(profile => {
            // Get Email
            const user = liff.getDecodedIDToken();
            // const email = user.email;
            // console.log(email);

            this.userId = profile.userId;
            axios.post(backURL + '/list/add', {
              userId: profile.userId,
              displayName: profile.displayName,
              pictureUrl: profile.pictureUrl,
              email: user.email
            }).then(res => {
              console.log(res.data);
            }).catch(err => {
              console.log("Error" + err);
            })
            // liff.closeWindow();
          })
        }
      })
      .catch((e) => {
        this.message = "LIFF init failed.";
        this.error = `${e}`;
      });
    },
    getProfile() {
      // Check user login status
      if (isLoggedIn) {
        return liff.getProfile().then(profile => profile);
      }
    },
    getCurrentEnv(){
      return new Promise((resolve, reject) => {
        axios.get(backURL + '/env/getCurrentEnv', {
        }).then(response => {
          // console.log(response.data);
          
          let currentDay = new Date().getDate();
          let lastDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();

          if ((currentDay >= 21 && currentDay <= lastDayOfMonth) && response.data[0].environmentStatus !== 'rest')
            this.currentEnvId = response.data[0].environmentId + 1;
          else
            this.currentEnvId = response.data[0].environmentId;
          resolve();
        }).catch(error => {
          console.log(error);
          reject();
        });
      });
    },
    getEnvInfo(){
      return new Promise((resolve, reject) => {
        axios.get(backURL + '/env/getEnvInfo', {
        }).then(response => {
          // console.log(response.data);
          this.envInfo = response.data;
          if (this.envInfo.length > 0 && this.currentEnvId) {
            this.paymentURL = this.envInfo.find(env => env.environmentId === this.currentEnvId).paymentLink;
          }
          resolve();
        }).catch(error => {
          console.log(error);
          reject();
        });
      });
    },
    checkPaymentInfo() {
      this.getCurrentEnv().then(() => {
        this.getEnvInfo().then(() => {
          this.paymentPicURL = this.basePaymentPicURL + this.currentEnvId + '.png';
        }).catch(error => {
          console.log(error);
        });
      }).catch(error => {
        console.log(error);
      });
    },


    createUserRecord() {
      return new Promise((resolve, reject) => {
        axios.post(backURL + '/list/createUserRecord', {
          userId: this.userId,
          action: 'newebPaymentLink',
        }).then(response => {
          console.log(response.data);
          resolve();
        }).catch(error => {
          console.log(error);
          reject();
        });
      });
    },
    handleClick(event) {
      event.preventDefault();
      this.createUserRecord().then(() => {
        window.location.href = this.paymentURL;
      });
    },
  },
  mounted() {
    this.liffDataGacha();
    this.checkPaymentInfo();
  }
}
</script>

<style>
body{ 
  margin: 0;
  padding: 0;
  background-color: hwb(0 0% 100%);
}
div{
  background-color: hwb(0 0% 100%);
}
img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>